<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="hub_admin_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Accessory Name</th>
              <th v-if="!user.role.includes('admin_scenario_department')">Last Week No.</th>
              <th v-if="!user.role.includes('admin_scenario_department')">Last Week Avg Rating</th>
              <th>Raise Issue</th>
              <th>Issue</th>
              <th>Pending</th>
              <th>Inprogress</th>
              <th>Solved</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(admin_scenario_accessory, index) in admin_scenario_accessories" :key="index">
              <td>{{index + 1 }}</td>
              <td>{{ admin_scenario_accessory.name }}</td>
              <td v-if="!user.role.includes('admin_scenario_department')">{{ admin_scenario_accessory.last_week  }}</td>
              <td v-if="!user.role.includes('admin_scenario_department')">{{ admin_scenario_accessory.last_week_average_rating  }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="createIssue(admin_scenario_accessory.id), $bvModal.show('add-update-issue-modal')"><i class="fe fe-plus mr-1"></i>Raise New Issue</a>
              </td>
              <td>{{ admin_scenario_accessory.issue_count }}</td>
              <td>{{ admin_scenario_accessory.pending_count }}</td>
              <td>{{ admin_scenario_accessory.inprogress_count }}</td>
              <td>{{ admin_scenario_accessory.solved_count }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="admin_scenario_accessories.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
    <add-update-issue :admin_scenario_accessory_id="admin_scenario_accessory_id" :staffs="staffs" @getAdminScenarioAccessories="getAdminScenarioAccessories"></add-update-issue>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateIssue from '@/views/adminScenario/issue/hub/modal/addUpdateIssue'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, addUpdateIssue },
  data() {
    return {
      admin_scenario_accessories: [],
      staffs: [],
      admin_scenario_accessory_id: null,
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getAdminScenarioAccessories()
  },
  methods: {
    getAdminScenarioAccessories() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/admin-scenario/issue/accessories')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.admin_scenario_accessories = response.data.admin_scenario_accessories
          this.staffs = response.data.staffs
        })
        .catch(error => {
          console.log(error)
        })
    },
    createIssue(adminScenarioAccessoryId) {
      this.admin_scenario_accessory_id = null
      this.admin_scenario_accessory_id = adminScenarioAccessoryId
    },
  },
}
</script>

<style scoped>

</style>
