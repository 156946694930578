<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-issue-modal" title="Issue Add/Update" size="lg" hide-footer @hidden="refreshData">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <form action="" enctype="multipart/form-data" files="true" role="form">
            <div class="form-row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="issue_description" class="col-form-label col-form-label-sm">Issue Description <span class="custom-required">*</span></label>
                <textarea v-model="issue_description" v-validate="'required'" name="issue_description" id="issue_description" rows="4" class="form-control form-control-sm" placeholder="Whats the issue about.."></textarea>
                <div class="invalid-feedback">Issue description is required</div>
              </div>
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="issue_image" class="col-form-label col-form-label-sm">Issue Image</label>
                <input type="file" ref="issueImage" v-validate="'image'" id="issue_image" name="issue_image" @change="getIssueImage()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Issue image must be image</div>
              </div>
              <div class="col-md-12 col-sm-12 col-lg-12" v-if="admin_scenario_accessory_id === 5">
                <label for="staff_id" class="col-form-label col-form-label-sm">Staff Name <span class="custom-required">*</span></label>
                <select v-model="staff_id" v-validate="'required_if:admin_scenario_accessory_id,5'"  id="staff_id" name="staff_id" class="form-control form-control-sm">
                  <option selected disabled :value="''">Select a staff</option>
                  <option v-for="(staff, index) in staffs" :key="index" :value="staff.id">{{ staff.name }}</option>
                </select>
                <div class="invalid-feedback">Staff name is required</div>
              </div>
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="email_address" class="col-form-label col-form-label-sm">Email Address </label>
                <input type="text" v-model="email_address" v-validate="'email'" name="email_address" id="email_address" class="form-control form-control-sm" placeholder="Enter email address" />
                <div class="invalid-feedback">Email address is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="createIssue">Create Issue</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-issue-modal')">Cancel</a-button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addUpdateIssue',
  props: ['admin_scenario_accessory_id', 'staffs'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
      issue_description: '',
      email_address: '',
      issue_image: '',
      staff_id: '',
    }
  },
  mounted() { },
  methods: {
    createIssue() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('admin_scenario_accessory_id', this.admin_scenario_accessory_id)
          formData.append('issue_description', this.issue_description)
          formData.append('email_address', this.email_address)
          formData.append('issue_image', this.issue_image)
          formData.append('staff_id', this.staff_id)
          apiClient.post('api/admin-scenario/issue/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.$bvModal.hide('add-update-issue-modal')
            this.loader = false
            if (response.data.error === false) {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('getAdminScenarioAccessories')
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    refreshData() {
      this.issue_description = ''
      this.issue_image = ''
    },
    getIssueImage() {
      this.issue_image = this.$refs.issueImage.files[0]
      if (this.issue_image.size > 3072000) {
        this.$notification.error({
          message: 'Image size not greater then 3 MB',
        })
        this.issue_image = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
